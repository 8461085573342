import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Divider,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useLocation, Link as RouterLink } from "react-router-dom";
import {
  openedMixin,
  closedMixin,
  drawerStyle,
  listItemBtnStyle,
  listItemIconStyle,
  listItemTextStyle,
} from "./styles/general";
import LogCallPopup from "./modules/logCallPopup";
import VersionViewer from "./modules/versionPopup";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";

const drawerWidth = 240;
const breakpoint = 990;
export default function Sidebar({ context }) {
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [versionOpen, setVersionOpen] = useState(false);
  const [logCallOpen, setLogCallOpen] = useState(false);
  const [mouseOut, setMouseOut] = useState(false);
  const [pageRefresh, setPageRefresh] = useState(false);

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme, context.theme, drawerWidth),
      "& .MuiDrawer-paper": openedMixin(theme, context.theme, drawerWidth),
    }),
    ...(!open && {
      ...closedMixin(theme, context.theme),
      "& .MuiDrawer-paper": closedMixin(theme, context.theme),
    }),
  }));

  const callCancel = () => {
    setLogCallOpen(false);
  };

  const handleMouseOut = () => {
    if (mouseOut) clearTimeout(mouseOut);
    if (!open) return;
    const temp = setTimeout(() => {
      setOpen(false);
    }, 3000);
    setMouseOut(temp);
  };

  const handleMouseIn = () => {
    if (!open) return;
    if (!mouseOut) return;
    clearTimeout(mouseOut);
    setMouseOut(false);
  };

  useEffect(() => {
    window.addEventListener("triggerDrawerOpen", () => {
      setOpen(true);
    });
    window.addEventListener(
      "resize",
      function (event) {
        if (pageRefresh) clearTimeout(pageRefresh);
        setPageRefresh(
          setTimeout(() => {
            setOpen(false);
            setPageRefresh(false);
          }, 2000)
        );
      },
      true
    );
  }, []);

  if (!context?.access || !typeof context.access === "object")
    return <Typography>No Navigation Found</Typography>;

  return (
    <>
      <LogCallPopup
        open={logCallOpen}
        cancel={callCancel}
        setOpen={setLogCallOpen}
      />
      <VersionViewer vOpen={versionOpen} setVOpen={setVersionOpen} />
      <Drawer
        variant="permanent"
        open={open}
        sx={drawerStyle(context.theme, open || window.innerWidth > breakpoint)}
      >
        <div
          id="sidebarDrawer"
          onMouseOut={() => {
            handleMouseOut();
          }}
          onMouseEnter={() => {
            handleMouseIn();
          }}
          className={open ? "open-drawer" : "closed-drawer"}
        >
          <div>
            <List>
              <ListItem
                onClick={() => {
                  setOpen(!open);
                }}
                sx={{ cursor: "pointer" }}
              >
                <ListItemIcon sx={{ color: context.theme.sidebar.font }}>
                  {open ? <ChevronLeft /> : <ChevronRight />}
                </ListItemIcon>
              </ListItem>
            </List>
            <Divider />
            <List>
              {Object.keys(context.access).map((item, key) => (
                <ListItem
                  key={key}
                  disablePadding
                  sx={{ display: "block", textAlign: "center" }}
                >
                  <ListItemButton
                    component={RouterLink}
                    to={`/${item}/index`}
                    sx={listItemBtnStyle(open)}
                    selected={
                      location.pathname.indexOf(
                        context.access[item].options.active_when
                      ) !== -1
                    }
                  >
                    <Tooltip
                      title={
                        <Typography variant="body1">
                          {context.access[item].options.side_menu_name}
                        </Typography>
                      }
                      arrow
                      placement="right"
                    >
                      <ListItemIcon sx={listItemIconStyle(open)}>
                        <Icon
                          style={{
                            color:
                              location.pathname.indexOf(
                                context.access[item].options.active_when
                              ) !== -1
                                ? context.theme.sidebar.active_font
                                : context.theme.sidebar.font,
                          }}
                        >
                          {context.access[item].options.icon_name}
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                    {open && (
                      <ListItemText
                        primary={context.access[item].options.side_menu_name}
                        sx={listItemTextStyle(open, context.theme)}
                      />
                    )}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </div>
          <div>
            <List>
              <ListItem
                disablePadding
                sx={{ display: "block", textAlign: "center" }}
              >
                <ListItemButton
                  sx={listItemBtnStyle(open)}
                  onClick={() => {
                    setLogCallOpen(true);
                  }}
                >
                  <Tooltip
                    title={<Typography variant="body1">Log Call</Typography>}
                    arrow
                    placement="right"
                  >
                    <ListItemIcon sx={listItemIconStyle(open)}>
                      <AddIcCallIcon
                        style={{ color: context.theme.sidebar.font }}
                      />
                    </ListItemIcon>
                  </Tooltip>
                  {open && (
                    <ListItemText
                      primary={"Log Call"}
                      sx={listItemTextStyle(open, context.theme)}
                    />
                  )}
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                sx={{ display: "block", textAlign: "center" }}
              >
                <ListItemButton
                  sx={listItemBtnStyle(open)}
                  onClick={() => {
                    setVersionOpen(true);
                  }}
                >
                  <Tooltip
                    title={
                      <Typography variant="body1">Version History</Typography>
                    }
                    arrow
                    placement="right"
                  >
                    <ListItemIcon sx={listItemIconStyle(open)}>
                      <HelpOutlineIcon
                        style={{ color: context.theme.sidebar.font }}
                      />
                    </ListItemIcon>
                  </Tooltip>
                  {open && (
                    <ListItemText
                      primary={"Version History"}
                      sx={listItemTextStyle(open, context.theme)}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            </List>
          </div>
        </div>
      </Drawer>
    </>
  );
}
